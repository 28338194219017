import { ItemType } from 'app/components/Common/InputDropDown';
import { de, enGB, es, fr, it, pt, ru, he, ar } from 'date-fns/locale';

export const localeMap: Record<string, Locale> = { 'en-GB': enGB, pt, es, de, fr, it, ru, he, ar };

export const roomTypes: ItemType[] = [
	{
		label: 'main.hotels.onePerson',
		value: 'one-person',
		icon: 'icon-one-person',
	},
	{
		label: 'main.hotels.twoPerson',
		value: 'two-person',
		icon: 'icon-two-persons',
	},
	{
		label: 'main.hotels.family',
		value: 'family',
		icon: 'icon-family',
	},
];
