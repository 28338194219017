import {
	SET_GROWTHBOOK_CALLBACK,
	SET_GROWTHBOOK_FEATURE_ENABLED,
	SET_GROWTHBOOK_FEATURE_VARIANT,
} from '../actions/growthbookActions';

type ActionTypes = 'SET_GROWTHBOOK_CALLBACK' | 'SET_GROWTHBOOK_FEATURE_ENABLED' | 'SET_GROWTHBOOK_FEATURE_VARIANT';

interface Action {
	type: ActionTypes;
	payload: any;
}

interface Feature {
	variant?: string;
	isEnabled?: boolean;
}

interface State {
	growthbookCallback: any;
	features: {
		[key: string]: Feature;
	};
}

const initialState: State = {
	growthbookCallback: null,
	features: {
		'google-signin': {
			isEnabled: false,
		},
	},
};

const actionMap = {
	[SET_GROWTHBOOK_CALLBACK]: (state: State, action: Action) => {
		return {
			...state,
			growthbookCallback: action.payload,
		};
	},
	[SET_GROWTHBOOK_FEATURE_ENABLED]: (state: State, action: Action) => {
		return {
			...state,
			features: {
				...state.features,
				[action.payload.featureId]: {
					...state.features[action.payload.featureId],
					isEnabled: action.payload.isEnabled,
				},
			},
		};
	},
	[SET_GROWTHBOOK_FEATURE_VARIANT]: (state: State, action: Action) => {
		return {
			...state,
			features: {
				...state.features,
				[action.payload.featureId]: {
					...state.features[action.payload.featureId],
					variant: action.payload.variant,
				},
			},
		};
	},
};

const reducer = (state: State = initialState, action: Action): State => {
	const reducer = actionMap[action.type];
	return reducer ? reducer(state, action) : state;
};

export default reducer;
