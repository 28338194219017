import { setContext } from '@sentry/nextjs';
import { format, setDefaultOptions } from 'date-fns';

import ApiInterface from '../app/modules/ApiInterface';
import Helper from '../app/modules/Helper';
import store from '../store';
import { parseFormData, submitHiddenForm } from './baseActions.utils';
import {
	AGREE_COOKIE_NOTICE,
	ENABLE_VERT,
	OPEN_CHILD_INPUT,
	OPEN_INPUT,
	PASSTHRU_SET_FOUND_PARTNER,
	PASSTHRU_SET_FOUND_PARTNER_LINK,
	PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY,
	PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL,
	PRICE_ALERT_SHOW_MODAL_SIGN_UP,
	REMOVE_ERRORS,
	SET_COUNTRY_CODE,
	SET_DURATION_DETECT_REDIRECT_LINK,
	SET_IS_BROWSER_SUPPORT_NOTIFICATION,
	SET_LANGUAGE,
	SET_PHONE_NUMBER,
	SET_PRICE_ALERT,
	SET_PRICE_ALERT_EMAIL,
	SET_PUSH_SUBSCRIPTION,
	SET_TICKET_DATA,
	SET_TOOLTIP_ERROR,
	SET_UID_IMID,
	SET_VAPID_KEY,
	SIGNIN_CHECKBOX_CHECKED,
	TOGGLE_AUTO_JUMP,
	TOGGLE_PREVENT_ENTER,
	TOGGLE_PREVENT_FOCUS,
	TOGGLE_PRICE_ALERT_HANDLER,
	TOGGLE_PROGRESS_LOADING,
	TOGGLE_SHOW_ERRORS,
	TOGGLE_SHOW_PHONE_NUMBER,
	USER_SIGNIN_SUCCESS,
} from './types';

import Cookies from 'js-cookie';
import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';

export const openInput = (inputId) =>
	catchErrorSync((dispatch) => {
		const w = parseInt(window.innerWidth);
		if (inputId && w < 575 && ['hotel-destination'].includes(inputId)) {
			document.body.classList.add('opened-mobile-back-overlay');
		} else {
			if (inputId && document.getElementById(inputId) && w < 700) {
				Helper.doScrolling('#' + inputId + '-parent', 500);
			}
			document.body.classList.remove('opened-mobile-back-overlay');
		}

		if (!inputId) {
			store.dispatch(toggleAutoJump(false));
		}
		dispatch({
			type: OPEN_INPUT,
			payload: inputId,
		});
	});

export const openChildInput = (inputId) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: OPEN_CHILD_INPUT,
			payload: inputId,
		});
	});

export const setTooltipError = (errors) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_TOOLTIP_ERROR,
			payload: errors,
		});
	});

export const removeErrors = () =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: REMOVE_ERRORS,
		});
	});

export const toggleShowErrors = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_SHOW_ERRORS,
			payload: toggle,
		});
	});

export const hideAndShowErros = () =>
	catchErrorSync((dispatch) => {
		const w = parseInt(window.innerWidth);
		if (w < 1200) {
			Helper.doScrolling('#filter-form', 500);
		}
	});

export const setLocationByIATACode = (IATA, type) =>
	catchErrorSync((dispatch) => {
		ApiInterface.instance
			.fetchAirport(IATA, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				const airport = res.data;
				if (!Helper.isEmpty(airport)) {
					dispatch({
						type,
						payload: airport,
					});
				}
			})
			.catch((e) => {});
	});

export const setCityByCode = (code, type) =>
	catchErrorSync((dispatch) => {
		ApiInterface.instance
			.fetchCity(code, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				if (!Helper.isNumeric(code)) {
					if (res.data.results.length) {
						dispatch({
							type,
							payload: Helper.convertElasticCityResultItemToAirportItem(res.data.results[0]),
						});
					}
				} else {
					const airport = res.data;
					if (!Helper.isEmpty(airport)) {
						dispatch({
							type,
							payload: airport,
						});
					}
				}
			})
			.catch((e) => {});
	});

export const enableVert = (vert) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: ENABLE_VERT,
			payload: vert,
		});
	});

export const toggleProgressLoading = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PROGRESS_LOADING,
			payload: toggle,
		});
	});

export const toggleAutoJump = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_AUTO_JUMP,
			payload: toggle,
		});
	});

export const agreeCookieNotice = () =>
	catchErrorSync((dispatch) => {
		try {
			localStorage.setItem('cookieNotice', true);
		} catch (error) {
			console.log('There was an error accessing LocalStorage', error);
		}

		dispatch({
			type: AGREE_COOKIE_NOTICE,
			payload: true,
		});
	});

export const sendDataToPassthruForm = (data, isTooLittleCompanies, openTwoTabs) =>
	catchErrorSync((dispatch) => {
		const queryStringData = store.dispatch(getQueryStringData());
		data = { ...queryStringData, ...data };
		if (isTooLittleCompanies) {
			const { fuid, buid, ...rest } = data;
			data = rest;
			if (openTwoTabs) {
				window.open('/passthru?' + Helper.serialize(data));
				data.tab = 'back';
				window.location.href = '/passthru?' + Helper.serialize(data);
				return;
			}
			window.location.href = '/passthru?' + Helper.serialize(data);
			return;
		}

		window.open('/passthru?' + Helper.serialize(data));
	});

export const init = (vert, growthbookCallback) =>
	catchErrorSync((dispatch) => {
		try {
			const lngLocalStorage = localStorage.getItem('i18nextLng');
			const lngInUrl = Helper.parseQueryString(window.location.search, true).lng;

			if (lngLocalStorage && lngLocalStorage.length === 2) {
				store.dispatch(setLanguage(lngInUrl || lngLocalStorage));
			}
		} catch (error) {
			console.log('There was an error accessing LocalStorage', error);
		}

		store.dispatch(isBrowserSupportNotification());
		store.dispatch(enableVert(vert));

		const parsed = Helper.parseQueryString(window.location.search);
		const lng = store.getState().base.lng;
		const theme = process.env.NEXT_PUBLIC_FRONT_SITE_THEME;

		ApiInterface.instance
			.init(vert, {
				...parsed,
				...{
					lng,
					site_theme: theme,
				},
			})
			.then((res) => {
				setContext('user', {
					id: res.data.uid,
					imid: res.data.imid,
				});
				store.dispatch(
					setUIDAndIMID({
						uid: res.data.uid,
						imid: res.data.imid,
					})
				);

				const growthbookCallback = store.getState().growthbook.growthbookCallback;
				if (growthbookCallback) {
					growthbookCallback({
						uid: res.data.uid,
						imid: res.data.imid,
						vert,
					});
				}
				const { closestAirport, userCountry } = res.data;
				if (userCountry) {
					store.dispatch(setCountryCode(userCountry));
					store.dispatch(checkToShowPhoneButton());
				} else {
					console.warn('No country detected :(');
				}
				store.dispatch(setPriceAlert(res.data.priceAlert));
				store.dispatch(setVapidKey(res.data.vapidKey));

				if (res.data.hasOwnProperty('closestAirport') && !Helper.isEmpty(closestAirport)) {
					store.dispatch(setCountryCode(closestAirport.countryCode));
					store.dispatch(checkToShowPhoneButton());
				}

				const { uid, imid } = res.data;
				window.dataLayer = window.dataLayer || [];
				dataLayer.push({
					event: 'facebookPixelInit',
					pixelExternalId: `${uid}${imid}`,
				});
			})
			.catch((e) => {});
	});

export const passthruSetFoundLink = (partner) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_SET_FOUND_PARTNER_LINK,
			payload: partner,
		});
	});

export const passthruFoundPartner = (partner) => {
	return catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_SET_FOUND_PARTNER,
			payload: partner,
		});
	});
};

export const submitForm = (form, data) => {
	return catchErrorSync(() => {
		store.dispatch(saveSearchParametersInCookie(data));
		const redirectByClient =
			Helper.bool(process.env.NEXT_PUBLIC_REDIRECT_BY_CLIENT) || Helper.hasPT(2) || Helper.hasPT(3);
		const queryStringData = store.dispatch(getQueryStringData());
		const parsed = Helper.parseQueryString(window.location.search, true);
		data = { ...data, ...queryStringData };

		if (redirectByClient) {
			if (data.tab === 'back' && !Helper.hasPT(3) && queryStringData.f !== '0' && parsed.autoredirect !== '1') {
				store.dispatch(passthruToggleRedirectImmediately(true));
			}

			const currentDate = new Date();
			const startTime = performance.now();

			ApiInterface.instance
				.clientRedirect(data.vert, parseFormData(data), {
					raxConfig: {
						retry: 1,
					},
				})
				.then((res) => {
					const endTime = performance.now();
					const responseTime = endTime - startTime;

					if (responseTime > 3000) {
						submitHiddenForm(data, form);
					}

					store.dispatch(setDurationDetectRedirectLink(new Date().getTime() - currentDate.getTime()));
					store.dispatch(passthruSetFoundLink(res.data['redirect-url']));
					store.dispatch(passthruFoundPartner(res.data.partner));
				})
				.catch((err) => {
					submitHiddenForm(data, form);
				});
		} else {
			submitHiddenForm(data, form);
		}
	});
};

export const getQueryStringData = () =>
	catchErrorSync((dispatch) => {
		const parsed = Helper.parseQueryString(window.location.search, true);
		const formData = {};
		if (parsed.country && parsed.country !== '') {
			formData['country'] = parsed['country'];
		}
		if (parsed['f'] && parsed['f'] !== '') {
			formData['f'] = parsed['f'];
		}
		if (parsed['fuid'] && parsed['fuid'] !== '') {
			formData['fuid'] = parsed['fuid'];
		}
		if (parsed['f-url'] && parsed['f-url'] !== '') {
			formData['f-url'] = parsed['f-url'];
		}
		if (parsed['b'] && parsed['b'] !== '') {
			formData['b'] = parsed['b'];
		}
		if (parsed['buid'] && parsed['buid'] !== '') {
			formData['buid'] = parsed['buid'];
		}
		if (parsed['b-url'] && parsed['b-url'] !== '') {
			formData['b-url'] = parsed['b-url'];
		}
		if (parsed['pt'] && parsed['pt'] !== '') {
			formData['pt'] = parsed['pt'];
		}
		if (parsed['lng'] && parsed['lng'] !== '') {
			formData['lng'] = parsed['lng'];
		}
		if (parsed['al'] && parsed['al'] !== '') {
			formData['al'] = parsed['al'];
		}
		if (parsed['bvert'] && parsed['bvert'] !== '') {
			formData['bvert'] = parsed['bvert'];
		}
		if (parsed['hchain'] && parsed['hchain'] !== '') {
			formData['hchain'] = parsed['hchain'];
		}
		if (parsed['plcid'] && parsed['plcid'] !== '') {
			formData['plcid'] = parsed['plcid'];
		}
		const enabledVert = store.getState().base.enabledVert;
		switch (enabledVert) {
			case 'cars':
				if (parsed['car-class'] && (parsed['car-class'] === 'van' || parsed['car-class'] === 'pickup')) {
					formData['car-class'] = parsed['car-class'];
				}
				if (parsed['caragency'] && parsed['caragency'] !== '') {
					formData['caragency'] = parsed['caragency'];
				}

				break;

			default:
				break;
		}

		return formData;
	});

export const passthruToggleRedirectImmediately = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PASSTHRU_TOGGLE_REDIRECT_IMMEDIATELY,
			payload: toggle,
		});
	});

export const setCountryCode = (code) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_COUNTRY_CODE,
			payload: code,
		});
	});

export const setUIDAndIMID = (data) =>
	catchErrorSync((dispatch) => {
		try {
			localStorage.setItem('tpdrops-uid', data.uid);
			localStorage.setItem('tpdrops-imid', data.imid);
		} catch (error) {
			console.log('localStorage is not available', error);
		}

		dispatch({
			type: SET_UID_IMID,
			payload: data,
		});
	});

export const saveSearchParametersInCookie = (data) =>
	catchErrorSync((dispatch) => {
		Cookies.set(`${data.vert}_search_data`, JSON.stringify(data), { expires: 7, path: '/' });
	});

export const checkCookieSearchData = () => catchErrorSync((dispatch) => {});

export const emitGMTEvent = (vert) =>
	catchErrorSync((dispatch) => {
		const dataHotels = store.getState().hotel;
		const n = dataHotels.hotelDestination.city ? dataHotels.hotelDestination.city : dataHotels.hotelDestination.name;
		const travelStart = format(new Date(dataHotels.checkInDate), 'yyyy-MM-dd');
		const travelEnd = format(new Date(dataHotels.checkOutDate), 'yyyy-MM-dd');

		if (process.env.NEXT_PUBLIC_SHOW_PRODUCTION_SCRIPTS !== 'true') {
			return;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'formHotelSearchSubmission',
			eventCategory: 'formSubmission',
			eventAction: 'formHotelSearch',
		});

		let pickupDestinationID = '';
		switch (dataHotels.hotelDestination.kayakType) {
			case 'airport':
				pickupDestinationID = dataHotels.hotelDestination.iata;
				break;
			case 'city':
				pickupDestinationID = 'C:' + dataHotels.hotelDestination.ptid.substr(1);
				break;
			case 'hotel':
				pickupDestinationID = 'H:' + dataHotels.hotelDestination.ptid.substr(1);
				break;
			default:
				break;
		}

		dataLayer.push({
			event: 'Search',
			items: [
				{
					content_type: 'destination',
					content_id: pickupDestinationID,
					city: n,
					region: dataHotels.hotelDestination.state,
					country: dataHotels.hotelDestination.country,
					travel_start: travelStart,
					travel_end: travelEnd,
				},
			],
		});
	});

export const setLanguage = (lng) =>
	catchErrorSync((dispatch) => {
		setDefaultOptions({ locale: Helper.getLocaleByCode(lng) });
		dispatch({
			type: SET_LANGUAGE,
			payload: lng,
		});
	});

export const setVapidKey = (vapidKey) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_VAPID_KEY,
			payload: vapidKey,
		});
	});

export const setPriceAlert = (priceAlert) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PRICE_ALERT,
			payload: priceAlert,
		});
	});

export const isBrowserSupportNotification = () =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_IS_BROWSER_SUPPORT_NOTIFICATION,
			payload: 'Notification' in window,
		});
	});

export const setPushSubscription = (pushSubscription) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PUSH_SUBSCRIPTION,
			payload: pushSubscription,
		});
	});

export const setPriceAlertEmail = (email) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PRICE_ALERT_EMAIL,
			payload: email,
		});
	});

export const togglePreventEnter = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PREVENT_ENTER,
			payload: toggle,
		});
	});

export const togglePreventFocus = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PREVENT_FOCUS,
			payload: toggle,
		});
	});

export const toggleShowPhoneNumber = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_SHOW_PHONE_NUMBER,
			payload: toggle,
		});
	});

export const setPhoneNumber = (phone) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_PHONE_NUMBER,
			payload: phone,
		});
		if (phone) {
			store.dispatch(toggleShowPhoneNumber(true));
		}
	});

export const checkToShowPhoneButton = () =>
	catchErrorSync((dispatch) => {
		const { countryCode, lng, enabledVert } = store.getState().base;
		const supportedCountryCodes = ['US', 'CA'];
		if (supportedCountryCodes.indexOf(countryCode) !== -1 || lng === 'es') {
			if (enabledVert === 'hotels') {
				// Hotels number
				store.dispatch(setPhoneNumber('855-329-5408'));
			} else if (enabledVert === 'flights') {
				// flights number
				if (lng === 'es') {
					store.dispatch(setPhoneNumber(Helper.trans('phoneNumbers.withoutPrefix')));
				} else {
					store.dispatch(setPhoneNumber('858-384-4820'));
				}
			}
		}
	});

export const setTicketData = (data) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_TICKET_DATA,
			payload: data,
		});
	});

export const setDurationDetectRedirectLink = (milliseconds) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SET_DURATION_DETECT_REDIRECT_LINK,
			payload: milliseconds,
		});
	});

export const togglePriceAlertHandler = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: TOGGLE_PRICE_ALERT_HANDLER,
			payload: toggle,
		});
	});

export const togglePriceAlertSignUpModal = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PRICE_ALERT_SHOW_MODAL_SIGN_UP,
			payload: toggle,
		});
	});

export const setClosedPriceAlertSignUpModal = (toggle) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: PRICE_ALERT_SET_IS_CLOSED_SIGN_UP_MODAL,
			payload: toggle,
		});
	});

export const setLocationByZip = (code, type) =>
	catchErrorSync((dispatch) => {
		ApiInterface.instance
			.fetchZipCode(code, {
				lng: store.getState().base.lng,
			})
			.then((res) => {
				const airport = res.data;
				if (!Helper.isEmpty(airport)) {
					dispatch({
						type,
						payload: airport,
					});
				}
			})
			.catch((e) => {});
	});

export const jumpToSearchInput = () =>
	catchErrorSync((dispatch) => {
		Helper.doScrolling('.navbar', 500);
		dispatch(openInput('hotel-destination'));
		document.getElementById('hotel-destination-input')?.focus();
		// @ts-ignore
		document.getElementById('hotel-destination-input')?.select();
	});

export const sendDataToCompare = (data) =>
	catchErrorSync((dispatch) => {
		const queryStringData = store.dispatch(getQueryStringData());
		data = { ...data, ...queryStringData };
		window.location.href = `/${store.getState().base.enabledVert}/compare-select?${Helper.serialize(data)}`;
	});

export const userSignIn = (data) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: USER_SIGNIN_SUCCESS,
			payload: data,
		});
	});

export const handleSigninCheckbox = (checked) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: SIGNIN_CHECKBOX_CHECKED,
			payload: checked,
		});
	});
