import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

interface CallbackFunction {
	(...args: any[]): any;
}

export const catchError = (callback: CallbackFunction) => {
	return async (...args: any) => {
		try {
			return await callback(...args);
		} catch (error) {
			console.error('Uncaught and reported error:', error);
			throw error;
		}
	};
};

export const catchErrorSync = (callback: CallbackFunction) => {
	return (...args: any) => {
		try {
			return callback(...args);
		} catch (error) {
			console.error('Uncaught and reported error:', error);
			throw error;
		}
	};
};

class AppErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false,
	};

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
	}

	public render() {
		return this.props.children;
	}
}

export default AppErrorBoundary;
