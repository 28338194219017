import { combineReducers } from 'redux';
import baseReducer from './baseReducer';
import passthruReducer from './passthruReducer';
import hotelReducer from './hotelReducer';
import compareReducer from './compareReducer';
import growthbookReducer from 'reducers/growthbookReducer';

export default combineReducers({
	base: baseReducer,
	hotel: hotelReducer,
	passthru: passthruReducer,
	compare: compareReducer,
	growthbook: growthbookReducer,
});
