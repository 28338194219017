import {
    SET_COMPARE_DATA, SET_COMPARE_RESULT, SET_FULL_SCREEN_INDEX
} from "../actions/compareTypes";

const initialState = {
    data: {},
    result: {},
    random: Math.random(),
    fullScreenIndex: ''
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SET_COMPARE_DATA:
            return {
                ...state,
                data: action.payload,
                random: Math.random()
            };
        case SET_COMPARE_RESULT:
            return {
                ...state,
                result: action.payload
            };

        case SET_FULL_SCREEN_INDEX:
            return {
                ...state,
                fullScreenIndex: action.payload
            };

        default:
            return state;
    }
}
