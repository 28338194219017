export const SET_GROWTHBOOK_FEATURE_ENABLED = 'SET_GROWTHBOOK_FEATURE_ENABLED';
export const SET_GROWTHBOOK_CALLBACK = 'SET_GROWTHBOOK_CALLBACK';
export const SET_GROWTHBOOK_FEATURE_VARIANT = 'SET_GROWTHBOOK_FEATURE_VARIANT';

interface DispatchType {
	(arg: { type: string; payload: any }): void;
}

export const setGrowthbookFeatureEnabled =
	({ featureId, isEnabled }: { featureId: string; isEnabled: boolean }) =>
	(dispatch: DispatchType) => {
		dispatch({
			type: SET_GROWTHBOOK_FEATURE_ENABLED,
			payload: { featureId, isEnabled },
		});
	};

export const setGrowthbookCallback = (callback: any) => (dispatch: DispatchType) => {
	dispatch({
		type: SET_GROWTHBOOK_CALLBACK,
		payload: callback,
	});
};

export const setGrowthbookVariant =
	({ featureId, variant }: { featureId: string; variant: string }) =>
	(dispatch: DispatchType) => {
		dispatch({
			type: SET_GROWTHBOOK_FEATURE_VARIANT,
			payload: { featureId, variant },
		});
	};
